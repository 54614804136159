import React, { useCallback, useEffect, useState } from 'react'
import Menu from '../Menu/Menu';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const countryNameToCode = {
  "AUS": "호주",
  "MYS": "말레이시아",
  "TES": "테스트",
  "VNM": "베트남",
  // 추가 국가와 코드 매핑
};

function Selectserialnumber() {
  const [tables, setTables] = useState([])

  const navigate = useNavigate()

  const selectTableName = useCallback(() => {
    fetch('https://ser.eyas.o-r.kr/select/tables', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((res) => res.json())
      .then(({ status, data }) => {
        if (status) {
          setTables(data);
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  })

  const detailClick = (table) => {
    const tableName = table
    navigate(`/dsn/${tableName}`);
    // console.log(tableName)
  };

  const creatSerial = () => {
    navigate('/csn')
  }

  useEffect(() => {
    selectTableName();
  }, []);


  return (
    <Menu>
      <Box sx={{
        float: 'left',
        width: 'calc(100% - 260px)',
        minWidth: '98%',
        minHeight: '95%',
        marginTop: '30px',
        overflowX: 'auto',
        padding: '10px 2px',
      }}>
        {tables.map((table, index) => (
          <Button
            key={index}
            variant="contained"
            color="primary"
            onClick={() => detailClick(table)}
            style={{ marginTop: '20px', marginRight: '10px', fontSize: '1.5rem' }}
          >
            {countryNameToCode[table] || table}
          </Button>
        ))}
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => creatSerial()}
          style={{ marginTop: '20px', marginRight: '10px', float: 'right', fontSize: '1.5rem' }}>
          시리얼 생성
        </Button>
      </Box>
    </Menu>
  )
}

export default Selectserialnumber
