import React, { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import '../../styles/core.css';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';

function Detailserialnumber() {
  const [sb, setSb] = useState([]);
  const [filterText, setFilterText] = useState('');

  const navigate = useNavigate();

  const { paramsTable } = useParams();
  const tableName = paramsTable;


  const updateSerialBoard = useCallback(() => {
    fetch('https://ser.eyas.o-r.kr/select/dsn', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tableName
      })
    })
      .then((res) => res.json())
      .then(({ status, data }) => {
        if (status) {
          setSb(data);
          console.log(data)
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  }, [tableName]);

  const columnTitles = {
    Serial_Index: 'Index',
    Serial_Number: '시리얼 번호',
    Serial_Use: '사용여부',
    Serial_Date: '날짜',
    Serial_Version: '시리얼 사용 버전',
    Device_ID: '디바이스 이름',
  };

  const getMaxCustomerLength = () => {
    let maxLength = 0;
    sb.forEach((item) => {
      const customerLength = item.description ? item.description.length : 0;
      if (customerLength > maxLength) {
        maxLength = customerLength;
      }
    });
    return maxLength;
  };

  const keys = Object.keys(columnTitles);
  const maxCustomerLength = getMaxCustomerLength();
  const customerColumnWidth = maxCustomerLength * 10; // 글자 하나의 예상 폭 (단위는 px)

  const columns = keys.map(key => ({
    field: key,
    headerName: columnTitles[key],
    width: key === 'description' ? customerColumnWidth : 150,
    editable: true,
  }));

  useEffect(() => {
    updateSerialBoard();
  }, [updateSerialBoard]);

  const filteredRows = sb.filter((item) =>
    keys.some((key) => {
      const value = item[key] ? item[key].toString().toLowerCase() : '';
      return value.includes(filterText.toLowerCase());
    })
  ).map((item, index) => ({
    id: index,
    ...item,
  }));

  return (
    <Menu>
      <Box sx={{
        float: 'left',
        width: 'calc(100% - 260px)',
        minWidth: '98%',
        height: '85vh',
        marginTop: '30px',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: '10px 2px',
      }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Typography sx={{ marginLeft: '30px' }} variant="h4" component="h2" gutterBottom>
            시리얼 넘버 관리
          </Typography>
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ padding: '5px', width: '500px', height: '40px' }}
          />
        </Box>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          style={{ height: '85%', width: '100%' }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '1.6rem', // 헤더 글자 크기 조절
            },
            '& .MuiDataGrid-cell': {
              fontSize: '1.5rem', // 셀 글자 크기 조절
            },
            '& .MuiTablePagination-select': {
              display: 'none'
            },
            '& .MuiTablePagination-selectLabel': {
              display: 'none'
            },
            '& .MuiTablePagination-actions': {
              fontSize: '1.8rem',
              marginRight: '15px'
            },
            '& .MuiTablePagination-displayedRows': {
              fontSize: '1.4rem', // 페이지 표시 폰트 크기 조정
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/asn", { state: { tableName } })}
          style={{ marginTop: '20px', float: 'right', fontSize: '1.2rem' }}
        >
          추가하기
        </Button>
      </Box>
    </Menu>
  );
}

export default Detailserialnumber;
